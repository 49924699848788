<template>
  <div class="animated fadeIn">
    <div v-if="displaySurvey && !surveySubmitted">
      <b-alert show variant="warning" class="mb-4">
        <i class="icon-info mr-1"></i>
        We are conducting a manditory survey to better understand your experience with N&nbsp;Zone&nbsp;Sports&trade;. 
        Please take a moment to complete the survey below. Your feedback is important to us.
        Thank you for your time.
      </b-alert>
      <Survey
        :year="surveyYear"
        :month="surveyMonth"
        :surveyId="1"
        @surveySubmitted="surveySubmitted = true"
      />
    </div>
    <b-form v-if="!displaySurvey || surveySubmitted" @submit.prevent="onSubmit">
      <b-row class="mb-4">
        <b-col cols="12" md="6">
          <b-card title="Revenue Report">
            <div class="clearfix">

              <!-- date pick -->
              <vue-monthly-picker
                class="float-left"
                v-model="reportMonth"
                :max="$moment().subtract(1, 'M')"
              ></vue-monthly-picker>

              <!-- view invoices -->
              <router-link class="float-right" :to="{ name: 'invoiceList', query: {
                type: invoicesBy,
                mo: reptMo,
                yr: reptYr
              } }" target="_blank">View Invoices</router-link>

            </div>
            <hr />
            <h5 class="mb-3">
              Invoices Included: {{ invoicesCounted.length }}
              <template v-if="invoicesOutstanding.length">
                <br />
                <small class="text-danger">Invoices Outstanding (unpaid): {{ invoicesOutstanding.length }}</small>
              </template>
            </h5>
            <!-- rept fields -->
            <div v-if="reportExists" class="bg-warning">
              <p class="p-4">Please select a month to report using the field above.</p>
            </div>
            <div v-else-if="!invoicesLoaded" class="bg-info">
              <p class="p-4">Just a moment while we gather invoices...</p>
            </div>
            <div v-else-if="reportSubmitted" class="bg-success">
              <p class="p-4">Submitting your report...</p>
            </div>
            <ReportFormSimple
              v-else
              class="mb-4" 
              :formFields="form" 
              :totalGroups="totalGroups" 
              @form-update="onUpdate"
            />
            <!-- total tax collected -->
            <template v-if="taxCollected">
              <hr />
              <h5 class="mb-3">Tax Collected: ${{ taxCollected.toFixed(2) }}</h5>
              <p>
                Tax collected is calculated by adding the tax amount from each invoice.
              </p>
            </template>
          </b-card>
        </b-col>
        <b-col>
          <p>
            Your royalties due are based on the revenue earned during the month by your N&nbsp;Zone&nbsp;Sports&trade; 
            branch and are due on the 1st of each month. Royalties submitted after the 10th of the month will be subject to late fees. 
            Your royalties due are calculated as (revenue x {{ royaltyPercentage | toPercent }}). Your minimum royalties due is ${{ royaltyMinimum }}.
          </p>
          <!-- div class="my-4">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Date</th>
                  <th>Amnt</th>
                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(inv, i) in invoicesCounted" :key="i">
                  <td>{{ (i += 1) }}</td>
                  <td>{{ inv.id }}</td>
                  <td>{{ $moment(inv.dateCreated).format('MM/DD') }}</td>
                  <td>{{ inv.amountDue }}</td>
                  <td>{{ inv.items.length }}</td>
                </tr>
              </tbody>
            </table>
            Total Invoices Amount Due: ${{ totalInvAmntDue }}
          </div -->
          <template v-if="invoicesLoaded">
            <h4>
              Royalties Due: ${{ royaltyAmount }}
            </h4>
            <b-form-textarea
            v-model="comments"
            placeholder="Comments"
            class="mb-3"
            ></b-form-textarea>
            <b-btn type="submit" variant="success" block>Submit</b-btn>
          </template>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import VueMonthlyPicker from 'vue-monthly-picker'
import ReportFormSimple from '@/components/ReportFormSimple'
import Survey from '@/components/Survey'
import reportsTpl from './revenue-fields' // @TODO: pull from admin settings
// import { revenueDefaults } from "./revenue-defaults"
import { constants } from "@/shared/constants"
import { useSetRevenueDefaults } from "@/composables/useRevenueDefaults"

export default {
  data () {
    return {
      form: reportsTpl.revenueFields,
      totalGroups: reportsTpl.totalGroups,
      reportMonth: this.$moment().subtract(1, 'M').startOf('month'),
      revenueTotal: 0,
      comments: '',
      reportExists: false,
      invoicesLoaded: false,
      invoicesCounted: [],
      taxCollected: 0,
      invoicesOutstanding: [],
      reportSubmitted: false,
      invoicesBy: 'datePaid',
      surveySubmitted: false
    }
  },
  computed : {
    ...mapGetters([
      'business',
      'invoices'
    ]),
    surveyMonth () {
      return this.$moment(this.reportMonth._d).add(1,'month').format('M')
    },
    surveyYear () {
      return this.$moment(this.reportMonth._d).add(1,'month').year()
    },
    totalInvAmntDue () {
      return Object.values(this.invoicesCounted).reduce((t, { amountDue }) => t + amountDue, 0)
    },
    reptMo () {
      return this.$moment(this.reportMonth._d).format('M')
    },
    reptYr () {
      return this.$moment(this.reportMonth._d).format('YYYY')
    },
    royaltyPercentage () {
      return parseFloat(this.business.royaltyPercentage)
    },
    royaltyMinimum () {
      return parseFloat(this.business.royaltyMinimum)
    },
    royaltyAmount () {
      let res = this.revenueTotal * this.royaltyPercentage
      if (res < this.royaltyMinimum) {
        res = this.royaltyMinimum
      }
      return res.toFixed(2)
    },
    displaySurvey () {
      return constants.SURVEY_MONTHS.includes(parseInt(this.surveyMonth))
    }
  },
  methods: {
    ...mapActions([
      'getInvoices',
      'getReportsRevenue',
      'postReportsRevenue'
    ]),
    async doGetInvoices () {
      const paramAfter = this.invoicesBy + '[after]'
      const paramBefore = this.invoicesBy + '[strictly_before]'
      let query = {
        business: this.business.id,
        pagination: false
      }
      query[paramAfter] = this.$moment(this.reportMonth._d).format()
      query[paramBefore] = this.$moment(this.reportMonth._d).add(1, 'M').format()
      await this.getInvoices(query)
    },
    checkSubmissions () {
      let params = {
        reptMo: this.reptMo,
        reptYr: this.reptYr
      }
      if (this.currentBusiness.id) {
        params.business = this.currentBusiness.id
      }
      return this.getReportsRevenue(params).then(resp => {
        if (resp.length) {
          this.reportExists = true
          this.showError({ message: 'You have already submitted your revenue report for the selected month.' })
        } else {
          this.reportExists = false
        }
      })
    },
    async setDefaults () {
      let revenueDefaults = await useSetRevenueDefaults(this.business.id, this.reportMonth)
      let defaults = revenueDefaults.defaults
      this.invoicesCounted = revenueDefaults.invoicesCounted
      this.invoicesOutstanding = revenueDefaults.invoicesOutstanding
      this.taxCollected = revenueDefaults.taxCollected
      for (let i in this.form) {
        for (let key in defaults) {
          if (this.form[i].name === key) {
            this.form[i].value = defaults[key]
          }
        }
      }

      this.invoicesLoaded = true
    },
    onUpdate (data) {
      this.revenueTotal = data.Revenue
    },
    onSubmit () {
      this.$bvModal.msgBoxConfirm('You are about to submit this revenue report. By submitting, you are confirming '
        +'that the data your are submitting was reviewed by you and is accurate. Once it is submitted, it cannot be modified. '
        +'Please click OK to confrim this action or click Cancel to continue editing.')
      .then(conf => {
        if (conf) {
          this.reportSubmitted = true
          this.postReportsRevenue({
            business: this.business["@id"],
            reptYr: parseInt(this.reptYr),
            reptMo: parseInt(this.reptMo),
            totalRevenue: this.revenueTotal,
            royalties: parseFloat(this.royaltyAmount),
            comments: this.comments,
            data: this.form,
            user: this.currentUser["@id"]
          }).then(resp => {
            this.$router.push({ name: 'reportsRevenueDetails', params: { rptId: resp.id } })
            this.showSuccess({ message: 'Thank you for submitting your report!' })
          })
        }
      })
    }
  },
  watch: {
    reportMonth () {
      this.reportExists = false
      this.invoicesLoaded = false
      this.reportSubmitted = false
      this.checkSubmissions().then(submitted => {
        if (!submitted) {
          this.setDefaults()
        }
      })
    }
  },
  components: {
    ReportFormSimple,
    VueMonthlyPicker,
    Survey
  },
  mounted () {
    this.checkSubmissions().then(submitted => {
      if (!submitted) {
        this.setDefaults()
      }
    })
  }
}
</script>
