export const revenueDefaults = Object.freeze({
  nzone_jr_clients: 0,
  nzone_jr_sales: 0,
  nzone_clients: 0,
  nzone_sales: 0,
  nzone_adult_clients: 0,
  nzone_adult_sales: 0,
  nzone_camp_clients: 0,
  nzone_camp_sales: 0,
  nzone_comm_clients: 0,
  nzone_comm_sales: 0,
  misc_clients: 0,
  misc_sales: 0,
  recurring_clients: 0,
  recurring_sales: 0,
  additional_sales: 0,
  deductions: 0
})