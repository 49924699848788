import store from '../store'
import { revenueDefaults } from '../views/reports/revenue-defaults'

export function useSetRevenueDefaults(bid, reportMonth) {

  let invoicesBy = 'datePaid'

  let defaults = { ...revenueDefaults }
  let invoicesCounted = []
  let invoicesOutstanding = []
  let taxCollected = 0
  
  const moment = require('moment')
  const addSales = (defaults, registration, amount) => {
    let addTo
    if (registration) {
      switch (registration.event.type.id) {
        case 1: addTo = 'nzone_jr'; break
        case 2: addTo = 'nzone'; break
        case 3: addTo = 'nzone_adult'; break
        case 4: addTo = 'nzone_camp'; break
        case 5: addTo = 'nzone_comm'; break
        case 6: addTo = 'misc'; break
        default: addTo = 'additional'
        // default: throw new TypeError("Invalid registration type ", registration.event.type.id)
      }
      defaults[addTo + '_clients']++
    }
    defaults[addTo + '_sales'] += parseFloat(amount)
    defaults[addTo + '_sales'] = parseFloat(defaults[addTo + '_sales'].toFixed(2))
    return defaults
  }
  const processInvoices = (invoices) => {
    for (let i1 in invoices) {

      if (!invoices[i1].items.length) continue  // disregard invoices with no items
  
      let thisInvoice = invoices[i1]
  
      // filter out items with no registration
      thisInvoice.items = thisInvoice.items.filter(item => item.registration)
      if (!thisInvoice.items.length) continue
  
      // separate invoices with no transaction id
      if (!thisInvoice.transactionId) {
        invoicesOutstanding.push(thisInvoice)
        continue
      }
  
      // update tax collected
      taxCollected += (thisInvoice.tax || 0)
      if (thisInvoice.tax) {
        // subtract tax from amount paid (does not count towards revenue)
        thisInvoice.amountDue -= thisInvoice.tax
      }
  
      // update defaults
      for (let i2 in thisInvoice.items) {
        let item = thisInvoice.items[i2]
        defaults = addSales(defaults, item.registration, (thisInvoice.amountDue / thisInvoice.items.length))
      }
  
      invoicesCounted.push(thisInvoice)
    }
  
    return {
      defaults,
      invoicesCounted,
      invoicesOutstanding,
      taxCollected
    }
  }
  
  const paramAfter = invoicesBy + '[after]'
  const paramBefore = invoicesBy + '[strictly_before]'
  const query = {
    business: bid,
    pagination: false
  }
  query[paramAfter] = moment(reportMonth?._d).format()
  query[paramBefore] = moment(reportMonth?._d).add(1, 'M').format()
  
  return store.dispatch('getInvoices', query).then(invoices => {
    return processInvoices(invoices)
  })
}